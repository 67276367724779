import "./styles.scss"

import React from "react"
import { motion } from "framer-motion"

import SectionHeader from "components/SectionHeader"
import Button from "components/Button"

import fadeAnimation from "utils/fadeAnimation"

const Content = () => {
  return (
    <section className="not-found-content">
      <div className="container-fluid">
        <SectionHeader title="Nie znaleziono" content="strony" type="white" />
        <div className="row">
          <div className="col-xl-11 offset-xl-1">
            <motion.div className="not-found-content__inner" {...fadeAnimation}>
              <h2>Przepraszamy, coś poszło nie tak.</h2>
              <p>
                Sprawdź dokładnie adres strony wpisany w przeglądarkę, aby
                upewnić się, że nie ma w nim żadnych literówek lub błędów. Jeśli
                adres jest poprawny, istnieje szansa, że nie posiadamy takiej
                podstrony na naszej stronie. Mamy za to szeroką ofertę tapet!
              </p>
              <div className="not-found-content__cta">
                <Button to="/tapety/">Oferta tapet</Button>
                <Button to="/" className="button--fill">
                  Strona główna
                </Button>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Content
